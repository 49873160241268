import { Button } from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import {
  Grid,
  GridItem,
  Text,
  VStack,
  Box,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import { Alert, AlertIcon } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { post } from "src/api";
import { setToken } from "src/utils/auth";
import { Checkbox } from "@chakra-ui/react";
import PasswordInput from "src/components/design-system/PasswordInput";
import Link from "src/components/design-system/Link";
import PageHeader from "src/components/design-system/PageHeader";

const LoginFormGridItem = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [staySignedIn, setStaySignedIn] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const login = useCallback(async () => {
    try {
      setLoading(true);
      setError("");
      const signinResult = await post("/public/signin", {
        username,
        password,
        staySignedIn,
      }).then((response) => response.json());
      if (signinResult.jwt) {
        setToken(signinResult.jwt);
        window.location.reload();
      } else {
        throw new Error("malformed response");
      }
    } catch (e) {
      console.warn(e);
      setError("check your password");
      setLoading(false);
    }
  }, [username, password, staySignedIn]);

  const submitOnEnter = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        login();
      }
    },
    [login]
  );

  return (
    <GridItem rowSpan={9}>
      <Grid templateColumns="repeat(4, 1fr)">
        <GridItem colSpan={1} />
        <GridItem colSpan={2}>
          <PageHeader>Welcome back.</PageHeader>
          <Text>
            Don’t have an account?{" "}
            <Link to="/signup" color="blue.700">
              Sign up
            </Link>
          </Text>
          <VStack marginTop="10" spacing="32px" onKeyPress={submitOnEnter}>
            <Input
              type="email"
              id="username"
              placeholder="Your email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Box w="100%">
              <PasswordInput password={password} setPassword={setPassword} />
              <Flex alignItems="center" marginTop="2">
                <Checkbox
                  defaultIsChecked
                  checked={staySignedIn}
                  onChange={() => setStaySignedIn(!staySignedIn)}
                  size="md"
                >
                  Keep me logged in
                </Checkbox>
                <Spacer />
                <Link to="/forgot">Forgot password</Link>
              </Flex>
            </Box>
            <Button
              w="100%"
              variant="primary"
              disabled={!username || !password}
              onClick={login}
              isLoading={loading}
              loadingText="Submitting"
              type="submit"
            >
              Sign in
            </Button>
            {error && (
              <Alert status="error">
                <AlertIcon />
                {error}
              </Alert>
            )}
          </VStack>
        </GridItem>
        <GridItem colSpan={1} />
      </Grid>
    </GridItem>
  );
};

export default LoginFormGridItem;
