import { IconButton } from "@chakra-ui/react";
import { ViewOffIcon, ViewIcon } from "@chakra-ui/icons";
import { InputGroup, Input, InputRightElement } from "@chakra-ui/react";
import React, { useState } from "react";

interface Props {
  password: string;
  setPassword: (newPassword: string) => void;
}

const PasswordInput = ({ password, setPassword }: Props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputGroup size="md">
      <Input
        pr="4.5rem"
        type={showPassword ? "text" : "password"}
        placeholder="Your password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        id="password"
      />
      <InputRightElement>
        <IconButton
          aria-label={showPassword ? "hide password" : "show password"}
          icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
          size="sm"
          variant="ghost"
          onClick={() => setShowPassword(!showPassword)}
        />
      </InputRightElement>
    </InputGroup>
  );
};

export default PasswordInput;
