import { GridItem } from "@chakra-ui/react";
import React from "react";
import LogoHeaderItem from "src/components/LogoHeaderItem";

const LogoGridItem = () => {
  return (
    <GridItem rowSpan={1} marginRight="auto" marginTop="16px">
      <LogoHeaderItem />
    </GridItem>
  );
};

export default LogoGridItem;
