import React from "react";
import { Link as StyledLink, LinkProps } from "@chakra-ui/react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

const Link = ({ children, ...rest }: RouterLinkProps & LinkProps) => {
  return (
    <StyledLink
      as={RouterLink}
      color={rest.color || "blue.700"}
      _hover={{ color: "blue.900" }}
      {...rest}
    >
      {children}
    </StyledLink>
  );
};

export default Link;
