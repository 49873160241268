const Button = {
  baseStyle: {
    color: "dark.700",
  },
  variants: {
    primary: {
      bgColor: "yellow.700",
      color: "dark.700",
      boxShadow: "md",
      _hover: {
        color: "dark.900",
        boxShadow: "lg",
      },
    },
    secondary: {
      bg: "dark.700",
      color: "white",
      _hover: {
        bg: "dark.900",
        boxShadow: "md",
      },
    },
    tertiary: {
      border: "1px solid",
      borderColor: "dark.300",
      color: "dark.700",
      _hover: {
        borderColor: "dark.700",
        boxShadow: "md",
        textDecoration: "none",
      },
    },
    warning: {
      bg: "red.500",
      color: "white",
      _hover: {
        bg: "red.700",
        boxShadow: "md",
      },
    },
    tableButton: {
      color: "dark.700",
    },
  },
};

export default Button;
