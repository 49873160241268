const styles = {
  global: {
    "*:focus": {
      boxShadow: "none !important",
    },
    body: {
      color: "black.900",
    },
  },
};

export default styles;
