import { SimpleSpanProcessor } from "@opentelemetry/tracing";
import { WebTracerProvider } from "@opentelemetry/web";
import { Resource } from "@opentelemetry/resources";
import { SemanticResourceAttributes } from "@opentelemetry/semantic-conventions";
import { BaseOpenTelemetryComponent } from "@opentelemetry/plugin-react-load";
import { ZoneContextManager } from "@opentelemetry/context-zone";
import { CollectorTraceExporter } from "@opentelemetry/exporter-collector";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { FetchInstrumentation } from "@opentelemetry/instrumentation-fetch";
import config from "src/config";

const tracer = (serviceName: string) => {
  const provider = new WebTracerProvider({
    resource: new Resource({
      [SemanticResourceAttributes.SERVICE_NAME]: serviceName,
    }),
  });

  const exporter = new CollectorTraceExporter({
    url: "https://ingest.lightstep.com:443/api/v2/otel/trace",
    headers: {
      "Lightstep-Access-Token": config.LIGHTSTEP_INGEST_KEY,
    },
  });

  provider.addSpanProcessor(new SimpleSpanProcessor(exporter));

  provider.register({
    contextManager: new ZoneContextManager(),
  });

  registerInstrumentations({
    instrumentations: [
      new FetchInstrumentation({
        applyCustomAttributesOnSpan: (span, request, result) => {
          if (result && result.status && result.status >= 500) {
            span.setAttribute("error", true);
            span.setAttribute("status", result.status);
          }
        },
        propagateTraceHeaderCorsUrls: [
          /http:\/\/localhost:8080.*/,
          /https:\/\/api.alldaisy.com.*/,
        ],
      }),
    ],
  });

  BaseOpenTelemetryComponent.setTracer(serviceName);

  return provider.getTracer(serviceName);
};

export default tracer;
