import { Grid } from "@chakra-ui/react";
import React from "react";
import HeroGridItem from "./HeroGridItem";
import LogoGridItem from "./LogoGridItem";

interface Props {
  contentGridItem: React.FC;
}

const UnauthedLayout = ({ contentGridItem: ContentGridItem }: Props) => {
  return (
    <Grid
      gap={4}
      templateRows="repeat(10, 1fr)"
      templateColumns="repeat(2, 1fr)"
      height="100%"
      width="90%"
      margin="auto"
    >
      <LogoGridItem />
      <HeroGridItem />
      <ContentGridItem />
    </Grid>
  );
};

export default UnauthedLayout;
