import React, { Suspense } from "react";
import { Box, Flex } from "@chakra-ui/react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
} from "react-router-dom";
import { BaseOpenTelemetryComponent } from "@opentelemetry/plugin-react-load";

import Sidebar from "src/components/Sidebar";
import withPageName from "src/hocs/withPageName";
import ConfigContextProvider from "src/contexts/configContextProvider";
import SitePagesContextProvider from "src/contexts/sitePagesContextProvider";
import DigContextProvider from "src/contexts/digContextProvider";
import UserContextProvider from "src/contexts/userContextProvider";
import PreferenceContextProvider from "src/contexts/preferenceContextProvider";
import { withErrorBoundary } from "@sentry/react";
import ErrorFallback from "src/components/ErrorFallback";
import Loader from "src/components/design-system/Loader";
import BillingContextProvider from "src/contexts/billingContextProvider";
import IntegrationContextProvider from "src/contexts/integrationContextProvider";
import LinkageContextProvider from "src/contexts/linkageContextProvider";
import usePageTracker from "src/hooks/usePageTracker";
import useHubspotChat from "src/hooks/useHubspotChat";
import OrgPreferenceContextProvider from "src/contexts/orgPreferenceContextProvider";
import useSegmentAnalytics from "src/hooks/useSegmentAnalytics";
import RunStatusToastContainer from "src/components/RunStatusToast/popoverContainer";

const NotFoundPage = React.lazy(() => import("src/pages/NotFoundPage"));
const HomePage = React.lazy(() => import("src/pages/HomePage"));
const BrokenLinksPage = React.lazy(() => import("src/pages/BrokenLinksPage"));
const BannedTermsPage = React.lazy(() => import("src/pages/BannedTermsPage"));
const AppPageChangesPage = React.lazy(
  () => import("src/pages/PageChangesPage")
);
const NewArticlesPage = React.lazy(() => import("src/pages/NewArticlesPage"));
const DigsPage = React.lazy(() => import("src/pages/DigsPage"));
const DigSubpage = React.lazy(() => import("src/pages/DigSubpage"));
const ScreenshotPage = React.lazy(() => import("src/pages/ScreenshotPage"));
const ArticleListPage = React.lazy(() => import("src/pages/ArticleListPage"));
const ArticleDetailsPage = React.lazy(
  () => import("src/pages/ArticleDetailsPage")
);
const ArticleRequestsPage = React.lazy(
  () => import("src/pages/ArticleRequestsPage")
);
const SetupPage = React.lazy(() => import("src/pages/SetupPage"));
const AppPageListPage = React.lazy(() => import("src/pages/AppPageListPage"));
const AppPageDetailsPage = React.lazy(
  () => import("src/pages/AppPageDetailsPage")
);
const GlossaryPage = React.lazy(() => import("src/pages/GlossaryPage"));
const UsersPage = React.lazy(() => import("src/pages/UsersPage"));
const PreferencesPage = React.lazy(() => import("src/pages/PreferencesPage"));
const WelcomePage = React.lazy(() => import("src/pages/WelcomePage"));
const BillingPage = React.lazy(() => import("src/pages/BillingPage"));
const IntegrationPage = React.lazy(() => import("src/pages/IntegrationPage"));
const IgnoreRulesPage = React.lazy(() => import("src/pages/IgnoreRulesPage"));
const StyleGuidePage = React.lazy(() => import("src/pages/StyleGuidePage"));

const wrapComponent = <P extends RouteComponentProps>(
  component: React.ComponentType<P>,
  pageName: string
): React.ComponentType<P> => {
  const NamedComponent = withPageName(component, `Daisy | ${pageName}`);
  const NamedBoundedComponent = withErrorBoundary(NamedComponent, {
    fallback: ({ error }) => <ErrorFallback error={error} />,
  });
  return (props: P) => <NamedBoundedComponent {...props} />;
};

const RouterGuts = () => {
  usePageTracker();
  useHubspotChat();
  useSegmentAnalytics();

  return (
    <>
      <RunStatusToastContainer />
      <Box>
        <Sidebar />
      </Box>
      <Box maxHeight="100vh" width="100%" overflowY="scroll" boxShadow="lg">
        <Suspense fallback={<Loader />}>
          <Switch>
            <Redirect path="/" exact to={"/dashboard"} />
            <Route
              path="/dashboard/links"
              component={wrapComponent(BrokenLinksPage, "Broken links")}
            />
            <Route
              path="/dashboard/terms"
              component={wrapComponent(BannedTermsPage, "Terms")}
            />
            <Route
              path="/dashboard/changes"
              component={wrapComponent(AppPageChangesPage, "App page changes")}
            />
            <Route
              path="/dashboard/newArticles"
              component={wrapComponent(NewArticlesPage, "New articles")}
            />
            <Route
              path="/dashboard"
              component={wrapComponent(HomePage, "Dashboard")}
            />
            <Route
              path="/articles/:articleId"
              component={wrapComponent(ArticleDetailsPage, "Article details")}
            />
            <Route
              exact
              path="/articles"
              component={wrapComponent(ArticleListPage, "Articles")}
            />
            <Route
              exact
              path="/article-requests"
              component={wrapComponent(ArticleRequestsPage, "Article requests")}
            />
            <Route
              path="/pages/:pageId"
              component={wrapComponent(AppPageDetailsPage, "App page details")}
            />
            <Route
              exact
              path="/pages"
              component={wrapComponent(AppPageListPage, "App pages")}
            />
            <Route
              path="/digs"
              exact
              component={wrapComponent(DigsPage, "Digs")}
            />
            <Route
              path="/digs/screenshots/:detailId"
              component={wrapComponent(ScreenshotPage, "Snapshots")}
            />
            <Route
              path="/digs/:evaluationId"
              component={wrapComponent(DigSubpage, "Dig details")}
            />
            <Route
              path="/setup/ignore-rules"
              component={wrapComponent(IgnoreRulesPage, "Setup | Ignore rules")}
            />
            <Route
              exact
              path="/setup"
              component={wrapComponent(SetupPage, "Setup")}
            />
            <Route
              path="/glossary"
              component={wrapComponent(GlossaryPage, "Setup glossary")}
            />
            <Route path="/team" component={wrapComponent(UsersPage, "Team")} />
            <Route
              path="/preferences"
              component={wrapComponent(PreferencesPage, "Preferences")}
            />
            <Route
              path="/signup"
              component={wrapComponent(WelcomePage, "Welcome")}
            />
            <Route
              path="/billing"
              component={wrapComponent(BillingPage, "Billing")}
            />
            <Route
              path="/integrations"
              component={wrapComponent(IntegrationPage, "Integrations")}
            />
            <Route
              path="/style-guide"
              component={wrapComponent(StyleGuidePage, "Style guide")}
            />
            <Route path="/loader" component={wrapComponent(Loader, "Loader")} />
            <Route component={wrapComponent(NotFoundPage, "Not Found")} />
          </Switch>
        </Suspense>
      </Box>
    </>
  );
};

class AuthedRouter extends BaseOpenTelemetryComponent {
  render() {
    return (
      <Flex>
        <UserContextProvider>
          <ConfigContextProvider>
            <SitePagesContextProvider>
              <DigContextProvider>
                <PreferenceContextProvider>
                  <BillingContextProvider>
                    <IntegrationContextProvider>
                      <LinkageContextProvider>
                        <OrgPreferenceContextProvider>
                          <Router>
                            <RouterGuts />
                          </Router>
                        </OrgPreferenceContextProvider>
                      </LinkageContextProvider>
                    </IntegrationContextProvider>
                  </BillingContextProvider>
                </PreferenceContextProvider>
              </DigContextProvider>
            </SitePagesContextProvider>
          </ConfigContextProvider>
        </UserContextProvider>
      </Flex>
    );
  }
}

export default AuthedRouter;
