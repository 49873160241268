const colors = {
  dark: {
    100: "#717D92",
    200: "#627088",
    300: "#596780",
    400: "#4E5D78",
    500: "#455571",
    600: "#364766",
    700: "#283A5B",
    800: "#14284B",
    900: "#0A1F44",
  },
  black: {
    100: "#717D92",
    200: "#627088",
    300: "#596780",
    400: "#4E5D78",
    500: "#455571",
    600: "#364766",
    700: "#283A5B",
    800: "#14284B",
    900: "#0A1F44",
  },
  gray: {
    100: "#FAFBFB",
    200: "#F7F8F9",
    300: "#F1F2F4",
    400: "#E1E4E8",
    500: "#C9CED6",
    600: "#B0B7C3",
    700: "#A7AEBB",
    800: "#98A1B1",
    900: "#8A94A6",
  },
  yellow: {
    100: "#fff",
    200: "#FEFDE5",
    300: "#FFFED5",
    400: "#FFFDAB",
    500: "#FFFC82",
    600: "#FFFA63",
    700: "#FFF92F",
    800: "#DBD522",
    900: "#B7B217",
  },
  green: {
    100: "#EAFDDC",
    200: "#D0FBBB",
    300: "#AEF596",
    400: "#8DEC7A",
    500: "#5CE050",
    600: "#3AC03A",
    700: "#28A132",
    800: "#19812A",
    900: "#0F6B25",
  },
  blue: {
    100: "#D5F9FE",
    200: "#ACEFFE",
    300: "#83DFFD",
    400: "#63CDFB",
    500: "#31B0F9",
    600: "#238AD6",
    700: "#1867B3",
    800: "#0F4990",
    900: "#093477",
  },
  red: {
    100: "#FFDCDA",
    200: "#FFB5B8",
    300: "#FF909F",
    400: "#FF7595",
    500: "#FF4784",
    600: "#DB337B",
    700: "#B72371",
    800: "#931665",
    900: "#7A0D5C",
  },
  orange: {
    100: "#FFF8D3",
    200: "#FFEEA8",
    300: "#FFE37C",
    400: "#FFD75C",
    500: "#FFC526",
    600: "#DBA21B",
    700: "#B78213",
    800: "#93640C",
    900: "#7A4E07",
  },
  // SHAME ON YOU FREJA
  sidebar: {
    background: "#FAF9F7",
  },
  pastel: {
    peach: "#F5BBA9",
    lightgreen: "#E1EBDA",
    darkgreen: "#32523A",
    brown: "#C5A59D",
    lavender: "#DBD6F3",
    salmon: "#F5926F",
    orange: "#F7A900",
    cobalt: "#827D9C",
    gray: "#C4C0CB",
    blue: "#20326D",
  },
};

export default colors;
