import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

const PageHeader = ({ children, ...rest }: Props & BoxProps) => {
  return (
    <Box as="h1" textStyle="pageHeader" {...rest}>
      {children}
    </Box>
  );
};

export default PageHeader;
