import { Image } from "@chakra-ui/react";
import { AspectRatio, GridItem } from "@chakra-ui/react";
import React from "react";
import hero from "./hero.png";

const HeroGridItem = () => {
  return (
    <GridItem rowSpan={10} alignItems="center" overflow="hidden">
      <AspectRatio ratio={612 / 742} h="100%" w="100%">
        <Image src={hero} maxH="742px" maxW="612px" margin="auto" />
      </AspectRatio>
    </GridItem>
  );
};

export default HeroGridItem;
