const Link = {
  variants: {
    blue: {
      color: "blue.700",
      _hover: { color: "blue.900" },
    },
  },
};

export default Link;
