import { Box, Grid, GridItem, Text, VStack } from "@chakra-ui/react";
import { Alert, AlertIcon } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { Input, Link as ChakraLink } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { post } from "src/api";
import Link from "src/components/design-system/Link";
import PageHeader from "src/components/design-system/PageHeader";
import PasswordInput from "src/components/design-system/PasswordInput";
import { setToken } from "src/utils/auth";
import { isEmail } from "src/utils/email";

const SignupFormGridItem = () => {
  const location = useLocation();
  const inviteToken = new URLSearchParams(location.search).get("inviteToken");
  const email = new URLSearchParams(location.search).get("email");
  const [name, setName] = useState("");
  const [username, setUsername] = useState(email || "");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const signup = useCallback(async () => {
    try {
      setLoading(true);
      setError("");
      const path = inviteToken ? "/public/accept" : "/public/signup";
      const body = inviteToken
        ? {
            user: {
              password,
              username,
              name,
            },
            token: inviteToken,
          }
        : {
            username,
            password,
            name,
          };

      const signinResult = await post(path, body).then((response) =>
        response.json()
      );
      if (signinResult.jwt) {
        setToken(signinResult.jwt);
        window.location.reload();
      } else {
        throw new Error("malformed response");
      }
    } catch (e) {
      console.warn(e);
      setError("check your info");
      setLoading(false);
    }
  }, [inviteToken, username, password, name]);

  const submitOnEnter = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        signup();
      }
    },
    [signup]
  );

  const invalidEmail = !isEmail(username);

  return (
    <GridItem rowSpan={9}>
      <Grid templateColumns="repeat(4, 1fr)">
        <GridItem colSpan={1} />
        <GridItem colSpan={2}>
          <PageHeader>Welcome!</PageHeader>
          <Text>
            Already have an account?{" "}
            <Link to="/" color="blue.700">
              Log in
            </Link>
          </Text>
          <VStack marginTop="40px" spacing="32px" onKeyPress={submitOnEnter}>
            <Input
              type="text"
              id="name"
              placeholder="Your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Box w="100%">
              <Input
                type="email"
                id="username"
                placeholder="Your email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                isInvalid={invalidEmail}
                errorBorderColor="red.300"
                disabled={!!inviteToken}
              />
              {invalidEmail && (
                <Alert status="error">Make sure to use a valid email</Alert>
              )}
            </Box>
            <PasswordInput password={password} setPassword={setPassword} />
            <Button
              w="100%"
              variant="primary"
              disabled={!username || !password || !name}
              onClick={signup}
              isLoading={loading}
              loadingText="Submitting"
              type="submit"
            >
              Sign up
            </Button>
            <Box as="p" textStyle="pageSubheader">
              By signing up you agree to our{" "}
              <ChakraLink
                isExternal
                href="https://storage.googleapis.com/daisy-legal/terms_of_use_current.docx"
                color="blue.700"
              >
                terms of use
              </ChakraLink>
              .
            </Box>
            {error && (
              <Alert status="error">
                <AlertIcon />
                {error}
              </Alert>
            )}
          </VStack>
        </GridItem>
        <GridItem colSpan={1} />
      </Grid>
    </GridItem>
  );
};

export default SignupFormGridItem;
