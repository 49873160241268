const Sticky = {
  baseStyle: {
    bgColor: "orange.100",
    p: "4",
    borderRadius: "xl",
    boxShadow: "xl",
  },
};

export default Sticky;
