const Input = {
  variants: {
    outline: {
      field: { borderColor: "gray.500", _placeholder: { color: "dark.200" } },
    },
    unstyled: {
      field: { _placeholder: { color: "dark.200" } },
    },
    flushed: {
      field: { _placeholder: { color: "dark.200" } },
    },
  },
};

export default Input;
