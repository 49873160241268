const textStyles = {
  Overline: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "10px",
    fontWeight: "medium",
    letterSpacing: ".8px",
    color: "dark.600",
    textTransform: "uppercase",
  },
  label: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: "bold",
    color: "dark.600",
    letterSpacing: ".8px",
    textTransform: "uppercase",
    marginTop: "4",
    marginBottom: "2",
  },
  cellSubheader: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: "normal",
    color: "dark.600",
    paddingTop: "0",
    marginTop: "-4",
  },
  pageSubheader: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: "normal",
    color: "dark.600",
    paddingTop: "0",
    marginTop: "-4",
  },
  pageAnnouncement: {
    fontFamily: "'DM Serif Display', serif",
    fontSize: "36px",
    fontWeight: "normal",
    color: "dark.700",
  },
  pageHeader: {
    fontFamily: "'DM Serif Display', serif",
    fontSize: "24px",
    fontWeight: "normal",
    pb: "4",
  },
  contentHeader: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: "semibold",
    pb: "4",
  },
  pageSuperHeader: {
    fontFamily: "'Inter', serif",
    fontSize: "18px",
    fontWeight: "normal",
    lineHeight: "30px",
  },
};

export default textStyles;
