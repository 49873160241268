const Tabs = {
  parts: ["tab"],
  baseStyle: () => ({
    tab: {
      paddingTop: "0 !important",
      paddingBottom: "16px !important",
      paddingInlineStart: "0 !important",
      paddingInlineEnd: "0 !important",
      marginRight: "16px",
      fontSize: "14px !important",
      fontWeight: "500",
    },
  }),
  defaultProps: {
    variant: "line",
    colorScheme: "green",
  },
};

export default Tabs;
