const Card = {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 6,
  },
  variants: {
    rounded: {
      padding: 8,
      borderRadius: "xl",
      boxShadow: "0px 1px 6px 0px var(--chakra-colors-gray-500)",
    },
    ghost: {
      padding: 8,
      borderRadius: "xl",
      borderStyle: "dashed",
      borderColor: "gray.500",
      borderWidth: "1px",
    },
  },
  defaultProps: {
    variant: "rounded",
  },
};

export default Card;
