import { useCallback, useContext, useEffect } from "react";
import { UserContext } from "src/contexts/userContextProvider";
import { getToken } from "src/utils/auth";
import config from "src/config";

declare global {
  interface Window {
    analytics: any;
  }
}

const segmentSnippet = `
  !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${config.SEGMENT_WRITE_KEY}";;analytics.SNIPPET_VERSION="4.15.3";
  analytics.load("${config.SEGMENT_WRITE_KEY}");
  analytics.page();
  }}();
`;

const useSegmentAnalytics = () => {
  const { user } = useContext(UserContext);

  const identify = useCallback(async () => {
    if (getToken() && user && !!window.analytics) {
      window.analytics.identify(user.id, {
        name: user.name,
        email: user.username,
        org: user.orgId,
      });
    }
  }, [user]);
  useEffect(() => {
    const segmentScriptNode = document.createElement("script");
    segmentScriptNode.type = "text/javascript";
    segmentScriptNode.text = segmentSnippet;
    window.document.head.appendChild(segmentScriptNode);
  }, []);
  useEffect(() => {
    identify();
  }, [identify, user]);
};

export default useSegmentAnalytics;
