import { Button } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Grid, GridItem, Text, VStack, HStack } from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import { Alert, AlertIcon } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { post } from "src/api";
import Link from "src/components/design-system/Link";
import PageHeader from "src/components/design-system/PageHeader";

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const submitForgot = useCallback(async () => {
    try {
      setLoading(true);
      setError("");
      setSuccess("");
      const res = await post("/public/forgot", {
        email,
      });
      if (res.status > 204) {
        throw new Error(res.statusText);
      }
      setSuccess(
        "If this is a valid username, an email has been sent with a reset link!"
      );
    } catch (e) {
      console.error(e);
      setError("check your info");
    } finally {
      setLoading(false);
    }
  }, [email]);

  const submitOnEnter = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        submitForgot();
      }
    },
    [submitForgot]
  );

  return (
    <GridItem rowSpan={9}>
      <Grid templateColumns="repeat(4, 1fr)">
        <GridItem colSpan={4} textAlign="center">
          <PageHeader>Forgot your password?</PageHeader>
        </GridItem>
        <GridItem colSpan={1} />
        <GridItem colSpan={2}>
          <Text>
            I remember! <Link to="/login">Sign in</Link>
          </Text>
          <VStack marginTop="40px" spacing="32px" onKeyPress={submitOnEnter}>
            <Input
              type="email"
              id="username"
              placeholder="Your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              w="100%"
              variant="primary"
              disabled={!email}
              onClick={submitForgot}
              isLoading={loading}
              loadingText="Submitting"
              type="submit"
            >
              Send reset
            </Button>
            {error && (
              <Alert status="error">
                <HStack spacing="10px">
                  <AlertIcon />
                  <Text>{error}</Text>
                </HStack>
              </Alert>
            )}
            {success && (
              <Alert status="success">
                <HStack spacing="10px">
                  <CheckCircleIcon />
                  <Text>{success}</Text>
                </HStack>
              </Alert>
            )}
          </VStack>
        </GridItem>
        <GridItem colSpan={1} />
      </Grid>
    </GridItem>
  );
};

export default ForgotPasswordForm;
