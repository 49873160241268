import { extendTheme } from "@chakra-ui/react";

import styles from "./styles";

import fonts from "./foundations/fonts";
import textStyles from "./foundations/textStyles";
import colors from "./foundations/colors";

import Button from "./components/button";
import Tabs from "./components/tabs";
import Input from "./components/input";
import Textarea from "./components/textarea";
import Select from "./components/select";
import Popover from "./components/popover";
import Card from "./components/card";
import Checkbox from "./components/checkbox";
import Radio from "./components/radio";
import Sticky from "./components/sticky";
import Alert from "./components/alert";
import Link from "./components/link";

const overrides = {
  styles,
  fonts,
  textStyles,
  colors,
  components: {
    Button,
    Tabs,
    Input,
    Textarea,
    Select,
    Popover,
    Card,
    Checkbox,
    Radio,
    Sticky,
    Alert,
    Link,
  },
};
export default extendTheme(overrides);
